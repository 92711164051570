import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { getCases, deleteCase, setIsNotCaseList } from "../../actions/cases";
import { formatDate } from "./utils";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";

import DeleteCaseButton from "./DeleteCaseButton";
import DuplicateCaseButton from "./DuplicateCaseButton";
import ExpiredAccountLink from "../users/ExpiredAccountModal";


export class Cases extends Component {
  state = {
    caseList:[],
    search:""
  }

  static propTypes = {
    cases: PropTypes.array.isRequired,
    getCases: PropTypes.func.isRequired,
    deleteCase: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    auth: PropTypes.object
  };

  componentDidMount() {
    this.props.getCases();
  }

    filterBySearch = e => {
      const query = e.target.value;
      this.setState({search:query})
      var updatedList = [...this.props.cases];
      updatedList = updatedList.filter((item) => {
        return item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
      this.setState({caseList:updatedList})
    };

    handleSubmit = e => {
      e.preventDefault();
    }

    componentWillUnmount() {
      this.props.setIsNotCaseList();
    }

  render() {

  let displayList = this.props.cases;
  if (this.state.search.length>0)
    displayList = this.state.caseList;

    return (
      <Fragment>
        <div className="container-fluid row mt-3 d-flex justify-content-between align-items-end">
          <h2>Mes <br /><strong>dossiers</strong></h2>
            {this.props.cases.length > 9 &&
              <div className="mb-2" style={{ minWidth: "300px" }}>
                <form onSubmit={this.handleSubmit}>
                  <input type="text" name="search" onChange={this.filterBySearch} placeholder="Filtrer par nom de dossier" className="form-control form-control-sm"/>
                </form>
              </div>
            }
          <div className={this.props.cases.length > 9?"":"ml-auto"}>
            {this.props.auth && this.props.auth.user && !this.props.auth.user.subscription_expired ?
              <Link to="/nouveau">
                <CreateNewFolderIcon fontSize="large" /> Créer un dossier
              </Link> :
              <ExpiredAccountLink title={<><CreateNewFolderIcon fontSize="large" /> Créer un dossier</>} expiryDate={this.props.auth.user.subscription_end_date} isAccountManager={this.props.auth && this.props.auth.user && this.props.auth.user.stripe_id} isBetaTester={this.props.auth && this.props.auth.user && this.props.auth.user.is_beta_tester} />}
          </div>
        </div>
        <table className="table table-striped mb-5">
          <thead>
            <tr>
              <th></th>
              <th>Nom du dossier</th>
              <th>Créé le</th>
              <th>Modifié le</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {this.props.cases.length > 0 ? (
              displayList.map(c => (
                <tr key={c.id}>
                  <td className="align-middle">{c.id}</td>
                  <td className="align-middle">
                    {this.props.auth && this.props.auth.user && !this.props.auth.user.subscription_expired ?
                      <Link to={`/dossier/${c.id}`}>{c.title}</Link> :
                      <ExpiredAccountLink title={c.title} expiryDate={this.props.auth.user.subscription_end_date} isAccountManager={this.props.auth && this.props.auth.user && this.props.auth.user.stripe_id} isBetaTester={this.props.auth && this.props.auth.user && this.props.auth.user.is_beta_tester} />}
                  </td>
                  <td className="align-middle">{formatDate(c.date_created, 0, true)}</td>
                  <td className="align-middle">
                    {c.date_created.slice(0, 19) !==
                      c.date_modified.slice(0, 19)
                      ? formatDate(c.date_modified, 0, true)
                      : "-"}
                  </td>
                  <td className="align-middle">
                    <DuplicateCaseButton legalCase={c} />
                  </td>
                  <td className="align-middle">
                    <DeleteCaseButton legalCase={c} />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">
                  {this.props.isLoading
                    ? "Chargement de vos dossiers en cours..."
                    : <>Pas de dossier à afficher,            <Link to="/nouveau">
                      créez votre premier dossier
                    </Link></>}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  cases: state.cases.cases,
  isLoading: state.cases.isLoading,
  auth: state.auth
});

export default connect(mapStateToProps, { getCases, deleteCase, setIsNotCaseList })(Cases);
