export const CASES_LOADING = "CASES_LOADING";
export const CASES_RECEIVED = "CASES_RECEIVED";
export const GET_CASE = "GET_CASE";
export const UPDATE_CASE_VERSION = "UPDATE_CASE_VERSION";
export const DUPLICATE_CASE = "DUPLICATE_CASE";
export const UPDATE_CASE = "UPDATE_CASE";
export const DELETE_CASE = "DELETE_CASE";
export const ADD_CASE = "ADD_CASE";
export const GET_ERRORS = "GET_ERRORS";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const IS_CASE_LIST = "IS_CASE_LIST";
export const IS_NOT_CASE_LIST = "IS_NOT_CASE_LIST";
